<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" xl="8">
        <AddObjective :view_type="view_type" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    AddObjective: () => import("@/components/task_manager/AddObjective.vue"),
  },

  props: ["view_type"],

  methods: {
    handle_create_template() {
      this.$router.push("/admin/kanban/task_manager/add");
    },
  },
};
</script>
